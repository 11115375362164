import React from "react"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import { Link } from "gatsby"
import font from "../../fonts/fonts.module.scss"
import setaW from "../../images/global/logo-seta-white.svg"
import setaB from "../../images/global/logo-seta-black.svg"
import setaO from "../../images/global/logo-seta-full.svg"

const Page = ({data}) => {
  const image = data.img.childImageSharp.fluid;
  
  return(
    <StyledPage fluid={image}>
      {data.title &&
        <Link to={data.link!=="/404" && data.link} className="page-link">
          <div className="page-content">
            <h1 className={font.aGB} style={{color: data.color}}>{data.title}</h1>
            {data.link!=="/404" && <p className="page-links">
              <img src={data.color==="#ffffff" ? setaW: setaB} alt={"seta"}/>
              <img src={setaO} alt={"seta"} className="hoverArrow"/>
              <span className={font.aGM} style={{color: data.color}}>{data.linkTxt}</span>
            </p>}
          </div>
        </Link>
      }
    </StyledPage>
  )
}

export default Page

const StyledPage = styled(BackgroundImage)`
  position: relative;
  width: 100%;
  height: 100%;
  .hoverArrow{
    opacity: 0;
    position: absolute;
    left: 0;
    transition: all 200ms ease-out;
  }
  .page-link{
    width: 100%;
    height: 100%;
    :hover{
      .page-content{
        .page-links{
          letter-spacing: 0.1em;
          transition: all 200ms ease-out;
          .hoverArrow{
            opacity: 1;
          }
        }
      }
    }
  }
  .leftPage-logo{
    position: absolute;
    top: 3vh;
    left: 10%;
    width: clamp(150px, 12vw, 350px);
  }
  .page-content{
    position: absolute;
    left: 10%;
    top: 4vh;
    width: 65%;
    .page-links{
      display: flex;
      align-items: center;
      margin-top: 1em;
      img{
        height: 1em;
        margin-right: 0.5em;
      }
    }
  }
`