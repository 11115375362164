import React from "react"
import Slider from "react-slick";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import setaB from "../../images/global/logo-seta-black.svg"

const Intro = ({data}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    customPaging: i => (
      <div className={"slider-paging page" + i}/>
    )
  };
  
  return(
    <StyledIntro>
      <div className="slider">
        <Slider {...settings}>
          <GatsbyImage image={data.leftPage.imgM.childImageSharp.gatsbyImageData} alt={data.rightPage.title} objectFit="cover" className="slide-img"/>
          <GatsbyImage image={data.rightPage.imgM.childImageSharp.gatsbyImageData} alt={data.rightPage.title} objectFit="cover" className="slide-img"/>
        </Slider>
        <div className="slide-index">
          <span className="slide-indicator"/>
          <span className="slide-indicator"/>
        </div>
      </div>
      <div className="desc">
        <h2 className={font.aGB}>{data.rightPage.title}</h2>
        {data.rightPage.link!=="/404" && <Link to={data.rightPage.link} className="page-link">
          <img src={setaB} alt={"seta"}/>
          <p className={font.aGM}>{data.rightPage.linkTxt}</p>
        </Link>}
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  .slide-img{max-height: 66vh;}
  .desc{
    margin: 3vh 10vw;
    .page-link{
      display: flex;
      align-items: center;
      margin-top: 1em;
      img{
        height: 1em;
        margin-right: 0.5em;
      }
    }
  }
  .slider{
    position: relative;
    width: 100%;
    .slide-index{
      position: absolute;
      .slide-indicator{}
    }

    .slick-dots{
      position: absolute;
      display: flex !important;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, -50%);
      li div{
        margin: 0 1.5vw;
        width: 3.5vw;
        height: 3.5vw;
        border-radius: 100%;
        background-color: #ffffff;
      }
      .slick-active div{background-color: #fa4616;}
    }
    .slick-list{
      overflow: hidden;
      .slick-track{
        display: flex;
        .slick-slide{
          width: 100vw;
        }
      }
    }
  }
`