import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import Div100vh from 'react-div-100vh'
import styled from "styled-components"

import setaEB from "../../images/global/seta-esquerda-black.svg"
import setaEW from "../../images/global/seta-esquerda-white.svg"
import setaDB from "../../images/global/seta-direita-black.svg"
import setaDW from "../../images/global/seta-direita-white.svg"

import Page from "./pageMobile"

const Intro = ({data}) => {
  const [project, setProject] = useState(0);
  const [reverse, setReverse] = useState(false);

  function changeProject(i){
    if(i===1){
      setReverse(false);
      if(project===data.pages.length-1){
        setProject(0);
      }
      else{
        setProject(project+1);
      }
    }
    else{
      setReverse(true);
      if(project===0){
        setProject(data.pages.length-1);
      }
      else{
        setProject(project-1);
      }
    }
  }
  
  return(
    <StyledIntro>
      {data.pages.map((data, i)=>(
        <CSSTransition in={project===i} timeout={350} classNames={reverse ? "switchModalReverse" : "switchModal"} unmountOnExit key={"project" + i+1}>
          <Page data={data}/>
        </CSSTransition>
      ))}
      <div className="project-btns">
        <button onClick={()=>{changeProject(-1)}}><img src={setaEW} alt="seta esquerda"/></button>
        <button onClick={()=>{changeProject(1)}}><img src={setaDW} alt="seta direita"/></button>
      </div>
    </StyledIntro>
  )
}

export default Intro

const StyledIntro = styled(Div100vh)`
  position: relative;
  overflow: hidden;
  width: 100%;
  .project-btns{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 95%;
  }
  

  /* react-transition-group */
  .switchModal-enter{
    left: 100% !important;
  }
  .switchModal-enter-active{
    left: 0 !important;
    transition: all 350ms ease-out;
  }
  .switchModal-exit{
    left: 0 !important;
  }
  .switchModal-exit-active{
    left: -100% !important;
    transition: all 350ms ease-out;
  }

  .switchModalReverse-enter{
    left: -100% !important;
  }
  .switchModalReverse-enter-active{
    left: 0 !important;
    transition: all 350ms ease-out;
  }
  .switchModalReverse-exit{
    left: 0 !important;
  }
  .switchModalReverse-exit-active{
    left: 100% !important;
    transition: all 350ms ease-out;
  }
`