import React, { useState, useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import styled from "styled-components"
import Div100vh from 'react-div-100vh'
import font from "../../fonts/fonts.module.scss"
import logoLaranja from "../../images/global/logo-laranja.svg"
import baixoLaranja from "../../images/global/seta-baixo-laranja.svg"
import cimaLaranja from "../../images/global/seta-cima-laranja.svg"
import baixoCinza from "../../images/global/seta-baixo-cinzenta.svg"
import cimaCinza from "../../images/global/seta-cima-cinzenta.svg"

import Page from "./intro-page"

const Slider = ({data}) => {
  const [page, setPage] = useState(0);
  const [reverseTransition, setReverseTransition] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchCurrent, setTouchCurrent] = useState(null);

  function touchMove() {
    if(!isScrolling && touchStart!==null){
      if(touchCurrent !== touchStart){
        if(touchCurrent < touchStart){
          if(page !== data.pages.length-1){
            setPage(page+1);
            setReverseTransition(true);
          }
        }
        else{
          if(page!==0) {
            setPage(page-1);
            setReverseTransition(false);
          }
        }
        setIsScrolling(true);
      }
    }
  }

  useEffect(() => {
    if(isScrolling) {
      setTimeout(function() {
        setIsScrolling(false);
      }, 1500);
    }
  }, [isScrolling]);

  const changeEvent = e => {
    let y = e.deltaY;
    if(y>0 && !isScrolling && page < data.pages.length-1){
      setPage(page+1);
      setReverseTransition(true)
      setIsScrolling(true);
    }
    else if(y<0 && !isScrolling && page!==0){
      setPage(page-1);
      setReverseTransition(false)
      setIsScrolling(true);
    }
  }

  const select = (i) => {
    if(i<0 || i>=data.pages.length){
      return;
    }
    if(i<page){
      setReverseTransition(false);
    }
    else{
      setReverseTransition(true);
    }
    setPage(i);
  }

  const setTouch = (e) => {
    setTouchStart(e.touches[0].clientY);
    setTouchCurrent(e.touches[0].clientY);
  }
  
  return(
    <StyledIntro onWheel={(e)=>changeEvent(e)} onTouchStart={(e)=>(setTouch(e))} onTouchMove={(e)=>(setTouchCurrent(e.touches[0].clientY))} onTouchEnd={touchMove}>
      <section className="intro-section">
        {data.pages.map((pageInfo, i)=>(
          <CSSTransition in={page===i} timeout={650} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit key={"pageL" + i}>
            <div className="page pageL">
              <Page data={pageInfo.leftPage}/>
            </div>
          </CSSTransition>
        ))}

        <div className="index">
          <button onClick={()=>{select(page-1)}}>
            <img src={page===0 ? cimaCinza : cimaLaranja} alt="seta"/>
          </button>
          <p className={font.aGR + " index-info"}>
            {page+1}/{data.pages.length}
          </p>
          <button onClick={()=>{select(page+1)}}>
            <img src={page===data.pages.length-1 ? baixoCinza : baixoLaranja} alt="seta"/>
          </button>
          <img src={logoLaranja} alt="Logo" className="index-logo"/>
        </div>

        {data.pages.map((pageInfo, i)=>(
          <CSSTransition in={page===i} timeout={650} classNames={reverseTransition ? "switchModal" : "switchReverse"} unmountOnExit key={"pageR" + i}>
            <div className="page pageR">
              <Page data={pageInfo.rightPage}/>
            </div>
          </CSSTransition>
        ))}
      </section>
    </StyledIntro>
  )
}


export default Slider

const StyledIntro = styled(Div100vh)`
  position: relative;
  overflow: hidden;
  width: 100%;
  .intro-section{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .page{
      position: absolute;
      height: 100%;
      width: 49%;
      max-width: calc(50% - 12.5px);
    }
    .pageL{
      left: 0;
      top: 0;
    }
    .pageR{
      right: 0;
      top: 0;
    }
    .index{
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      width: 2%;
      min-width: 25px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .index-info{
        color: #fa461d;
        margin: 1em 0;
      }
      .index-logo{
        position: absolute;
        bottom: 25px;
        width: 45%;
      }
    }
  }
  

  /* react-transition-group */
  .switchReverse-enter {
    position: absolute;
    top: -100% !important;
  }
  .switchReverse-enter-active {
    position: absolute;
    top: 0 !important;
    transition: all 650ms ease-out;
  }
  .switchReverse-exit {
    position: relative;
    top: 0 !important;
  }
  .switchReverse-exit-active{
    position: absolute;
    top: 100% !important;
    transition: all 650ms ease-out;
  }

  .switchModal-enter{
    position: absolute;
    top: 100% !important;
  }
  .switchModal-enter-active{
    position: relative;
    top: 0 !important;
    transition: all 650ms ease-out;
  }
  .switchModal-exit{
    position: absolute;
    top: 0 !important;
  }
  .switchModal-exit-active{
    position: absolute;
    top: -100% !important;
    transition: all 650ms ease-out;
  }
`