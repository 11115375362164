import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Menu from "../components/menu/menu"
import Intro from "../components/intro/intro"
import IntroMobile from "../components/intro/introMobile"

const IndexPage = ({data}) => (
  <Layout inv={data.globalJson.invisual}>
    <SEO title="Home" />
    {useBreakpoint().mobile ? (
      <>
        <Menu data={data.globalJson.menu}/>
        <IntroMobile data={data.homeJson}/>
      </>
    ) : (
      <>
        <Menu data={data.globalJson.menu}/>
        <Intro data={data.homeJson}/>
      </>
    )}
  </Layout>
)

export default IndexPage

export const Json = graphql`
  query home {
    homeJson{
      index{
        link
        linkTxt
      }
      pages{
        leftPage{
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imgM{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                width: 3840
              )
            }
          }
        }
        rightPage{
          img{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imgM{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                width: 3840
              )
            }
          }
          title
          color
          link
          linkTxt
        }
      }
    }
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
              txt
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
    }
  }
`